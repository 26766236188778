import classNames from "classnames"
import { useRouter } from "next/router"
import { useAuth } from "lib/auth"
import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"
import { useCart } from "lib/cart"
import { getFrom } from "lib/redirects"

import SearchModal from "components/modals/search"
import { Link } from "components/common"
import { UserMenu } from "./user-menu"
import { LangMenu } from "./lang-menu"

export const Navigation = ({ mobile = false }) => {
	const { user, showLogin, signout, connecting } = useAuth()
	const { cart, showCart } = useCart()
	const { show } = useModal()
	const { c } = useTranslation("common")
	const router = useRouter()
	const currentRoute = getFrom(router ? router.route : "/")

	const showSearch = () => {
		show({
			id: "search",
			children: <SearchModal />,
			className: "slide top short"
		})
	}

	return (
		<nav className={`nav-user ${ mobile ? "only-mobile" : "only-desktop" }`}>
			<a
				href="#"
				onClick={e => { e.preventDefault(); showSearch() }}
				rel="nofollow noopener"
				title={c("search")}
				data-overlay="#overlay-search"
				className="icon-search"
			>
				{c("search")}
			</a>
			<Link
				target="/about"
				title={c("about")}
				aTitle={c("about")}
				className={classNames("icon-cinetek", { active: currentRoute === "/about"})}
			/>
			<Link
				target="/ways-to-watch"
				title={c("offers")}
				aTitle={c("offers")}
				className={classNames("icon-offers", { active: currentRoute.includes("/ways-to-watch") })}
			/>
			{
				connecting
				? <div className="loader is-connecting"></div>
				: user
					?	mobile
						? <Link
								target="/profile"
								title={c("profile")}
								className="icon-profile"
							/>
						: <UserMenu />
					: <Link
							title={c("login")}
							className="icon-login"
							onClick={showLogin}
							target="#"
							rel="nofollow noopener"
						/>
				}
			{
				cart && cart.content.items.length > 0
				? <Link
					target="#"
					rel="nofollow noopener"
					title={mobile ? c("cart") : ""}
					atitle={c("cart")}
					indice={cart && cart.content.items.length}
					className="icon-cart"
					onClick={showCart}
				/>
				: <div></div>
			}
			{
				mobile && user
				&& <Link
					target="#"
					rel="nofollow noopener"
					onClick={signout}
					title={c("logout")}
					className="icon-logout"
				/>
			}
			<LangMenu />
		</nav>
	)
}
