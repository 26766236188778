const langFromQuery = (query) => {
	const parts = query.split("-")

	if (parts.length > 2) {
		throw new Error(`langFromQuery is badly formatted (${query})`)
	}

	if (parts.length < 2) {
		return query
	}

	return parts[1]
}

export default langFromQuery
