import { useState } from "react"
import classNames from "classnames"
import moment from "moment"

import { useTranslation } from "lib/i18n/translation"
import { useCart } from "lib/cart"
import { useAuth } from "lib/auth"
import { useAccesses } from "lib/accesses"
import { ANNUAL, ANNUAL_PLUS, MONTHLY } from "lib/tools/subscriptions-list"

import { GiftOptionsForm } from "components/forms/gift-options"
import OneRoll from "static/assets/icons/tickets_roll.svg"
import ManyRolls from "static/assets/icons/many-rolls.svg"
import Tickets from "static/assets/icons/rolls-and-right-tickets.svg"

const FOR_ME = "me"
const FOR_OFFER = "offer"

export const AddSvodToCart = ({ product }) => {
	const { c } = useTranslation("common")
	const { addProductToCart, subscriptions, adding } = useCart()
	const { user } = useAuth()
	const { subscription } = useAccesses()

	const [current, setCurrent] = useState(null)
	const [currentCountry, setCurrentCountry] = useState(null)
	const [error, setError] = useState(false)
	const [currentTarget, setCurrentTarget] = useState(FOR_OFFER)

	const handleSubmitted = (gift, option) => {
		setError(false)
		if (currentTarget == FOR_OFFER) {
			if (!current) {
				setError(c("subscription-required"))
			} else if (!option) {
				setError(c("gift-option-required"))
			} else if (gift.dateSend && moment(gift.dateSend) < moment().startOf("day")) {
				setError(c("gift-date-send-to-low"))
			} else if (user && (gift.email === user.profile.email) && subscription !== null ) {
				setError(c("gift-subscription-already-active"))
			} else {
				addProductToCart(current, { gift })
					.catch(() => {
						setError(c("error-while-adding-gift-to-cart"))
					})
			}
		} else {
			if (!current) {
				setError(c("subscription-required"))
			} else {
				addProductToCart(current)
			}
		}
	}

	const onCountryChanged = (country) => {
		setCurrentCountry(country)

		if (current) {
			const subId = country.subscriptions.find(sub => sub.key === current.key)
			const subscription = subscriptions.find(sub => parseInt(sub.id, 10) === subId.id)
			setCurrent(subscription)
		}
	}

	return (
		<>
			<h2 className="overlay-title icon-selection-alt">{c("subscription")}</h2>
			<form aria-label={c("subscription-to-month-selection")}>
				<fieldset>
					<legend className="hidden">{c("to-choose-month-selection-to-offer")}</legend>
					<legend className="hidden">{c("choose-cinepass-for-me-or-to-offer")}</legend>
					<nav class="switch nav-tabs">
						<a
							href="#"
							title={c("for-me")}
							class={classNames({ "active": currentTarget === FOR_ME, "disabled": subscription !== null })}
							onClick={() => { setCurrentTarget(FOR_ME) }}
						>
							{c("for-me")}
						</a>
						<a
							href="#"
							title={c("to-offer")}
							class={classNames({ "active": currentTarget === FOR_OFFER })}
							onClick={() => { setCurrentTarget(FOR_OFFER) }}
						>
							{c("to-offer")}
						</a>
					</nav>
					<p>{c("offer-subscription")}</p>
					<nav className="offers blocks">
						<SubscriptionOffer
							country={currentCountry}
							subscriptions={subscriptions}
							setCurrent={setCurrent}
							target={currentTarget}
							current={current}
						/>
					</nav>
					<GiftOptionsForm
						product={{ ...current, ...product }}
						active={currentTarget == FOR_OFFER}
						error={error}
						adding={adding}
						onSubmit={handleSubmitted}
						onCountryChanged={onCountryChanged}
					/>
				</fieldset>
			</form>
		</>
	)
}

const SubscriptionOffer = ({ country, subscriptions, setCurrent, target, current }) => {
	if (!country) {
		return <div className="loading" />
	}

	const { c } = useTranslation()
	const availables = {
		[FOR_ME]: [
			{ key: MONTHLY, icon: OneRoll },
			{ key: ANNUAL, icon: ManyRolls },
			{ key: ANNUAL_PLUS, icon: Tickets }
		],
		[FOR_OFFER]: [
			{ key: ANNUAL, icon: ManyRolls },
			{ key: ANNUAL_PLUS, icon: Tickets }
		]
	}

	return (
		<>
			{
				subscriptions.map(subscription => {
					let sub = country.subscriptions.find(sub => sub.id == subscription.id)

					if (!sub) {
						return null
					}

					const key = sub.key
					const available = availables[target].find(item => item.key === key)

					if (!available) {
						return null
					}

					return (
						<div
							key={`subscription-${subscription.id}`}
							className="item"
						>
							<a
								onClick={e => { e.preventDefault(); setCurrent({...subscription, key}) }}
								title={c("subscribe")}
								className={classNames(
									"item-content",
									{ "active": current && current.id == subscription.id }
								)}
							>
								<figure className={classNames("svod-ratio", { "large": available.key === ANNUAL_PLUS })}>
									<available.icon title={c(`${available.key.replace("-", "_")}-subscription-icon-title`)} />
								</figure>
								<div className="infos">
									<h3 className="title" dangerouslySetInnerHTML={{ __html: c(`${key}_subscription_long`) }} />
									<p className="price">
										{subscription.extension.accessModes[0].price}€ {c(available.key === MONTHLY ? "per-month" : "per-year").toUpperCase()}
										{
											available.key === MONTHLY
											&& <small>{c("svod-without-obligation")}</small>
										}
									</p>
								</div>
							</a>
						</div>
					)
				})
			}
		</>
	)
}
