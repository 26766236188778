import { Form, Input } from "@rocketseat/unform"
import { useMutation } from "@apollo/react-hooks"

import { useEffect, useState } from "react"
import times from "lodash.times"
import * as Yup from "yup"
import slugify from "slugify"

import { useTranslation } from "lib/i18n/translation"
import { SPONSOR_MUTATION } from "lib/graphql/mutations"
import { getToken } from "lib/graphql/apollo/token"
import { useAnchorNav } from "lib/anchor-nav"
import { useAuth } from "lib/auth"

const schema = (c) => {
	return Yup.object().shape({
		emails: Yup.array()
			.of(Yup.string().email()),
		message: Yup.string()
	})
}

const MySponsors = ({ title }) => {

	const { user } = useAuth()
	const { c } = useTranslation("common")

	const [error, setError] = useState()
	const [newSponsoredAction, { data, loading }] = useMutation(SPONSOR_MUTATION)

	const onSubmit = async (values, { resetForm }) => {

		const emails = values.emails.filter(e => e && e !== "").filter(e => e !== user.profile.email)
		const message = values.message

		const { data: { sendSponsors } } = await newSponsoredAction({
			variables: {
				token: getToken(),
				emails,
				message
			},
			context: { clientName: "admin" }
		})

		if (sendSponsors.result === "ok") {
			resetForm()
			setError(null)
		}

		if (sendSponsors.result === "ko") {
			setError(sendSponsors.message
				? c("sponsors-not-successful-already-sponsor")
				: c("sponsors-not-successful")
			)
		}
	}

	const emailsCount = 10

	const {
		isSectionsSetted,
		setSectionLoaded
	} = useAnchorNav()

	useEffect(() => setSectionLoaded(title), [isSectionsSetted])

	return (
		<section id={slugify(c(title)).toLowerCase()} className="section-anchor">
			<Form
				schema={schema(c)}
				className="full"
				onSubmit={onSubmit}
				aria-label={c("sponsors-your-friends")}
			>
				<ol className="friends-list columnizer">
					{times(emailsCount, (i) => (
						<li key={`friends-item-${i}`}>
							<span className="form-label hidden">{`${c("email-friend")} ${i + 1}`}</span>
							<span className="input-container icon-guest">
								<Input type="email" placeholder={c("friend-email")} name={`emails[${i}]`} />
							</span>
						</li>
					))}
				</ol>
				<Input multiline name="message" placeholder={c("your-message-optional")} />
				{data && (
					error
						? <p>{error}</p>
						: <p>{c("sponsors-successful")}</p>
				)}
				<button
					type="submit"
					aria-label={c("send-invitations")}
					className={loading ? "is-loading" : ""}
				>
					{c("send-invitations")}
				</button>
			</Form>
		</section>
	)
}

export default MySponsors
